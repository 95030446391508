<template>
    <div ref="root">
        <styled-button
            :loading="props.loading"
            :icon="['fad', 'rotate-right']"
            size="small"
            @click.prevent="emits('loadMore')"
        >
            Load More
        </styled-button>
    </div>
</template>

<script setup lang="ts">
    import { library } from '@fortawesome/fontawesome-svg-core';
    import { faRotateRight } from '@fortawesome/pro-duotone-svg-icons';

    library.add(faRotateRight);

    export interface Props {
        loading?: boolean;
    }

    const props = withDefaults(defineProps<Props>(), {
        loading: false,
    });

    const emits = defineEmits([ 'loadMore' ]);

    const root = ref<Element | null>(null);
    const observer = ref<IntersectionObserver | null>(null);

    onMounted(() => {
        observer.value = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting && !props.loading) {
                    emits('loadMore');
                }
            });
        }, {
            root: null,
            rootMargin: '0px',
            threshold: 0.2,
        });

        observer.value.observe(root.value!);
    });

    onUnmounted(() => {
        observer.value?.disconnect();
    });
</script>
