import type { Ref } from 'vue';
import type { DocumentNode, OperationVariables } from '@apollo/client';
import type { PageInfo } from '~/generated/types';
import type { ApolloQueryResult } from '@apollo/client/core';
import type { ExecutableDefinitionNode, FieldNode } from 'graphql/language/ast';

export type ListFactory<TData, TConnection, TVariables> = {
    result: Ref<TData | undefined>;
    data: Ref<TConnection | undefined>;
    loading: Ref<boolean>;
    hasMore: Ref<boolean>;
    refetch: (variables?: TVariables) => Promise<ApolloQueryResult<TData>> | undefined;
    loadMoreResults: (variables?: TVariables) => Promise<void>;
}

export type ListVariables = {
    first?: number;
    after?: string;
} & OperationVariables;

export type ListConnection = {
    edges: any[];
    pageInfo: PageInfo;
}

export type GetDataFunction<TData, TConnection> = (data: TData | undefined) => TConnection | undefined;

export function createListFactory<
    TData,
    TConnection extends ListConnection = ListConnection,
    TVariables extends ListVariables = ListVariables
>(
    query: DocumentNode,
    variables: Ref<TVariables>,
    getData: GetDataFunction<TData, TConnection>
): ListFactory<TData, TConnection, TVariables> {

    const v = computed<TVariables>(() => {
        return {
            first: PAGE_SIZE,
            ...variables.value,
        } as TVariables;
    });

    const { result, loading, refetch, fetchMore } = useQuery<TData, TVariables>(query, () => {
        return v.value;
    }, {
        keepPreviousResult: true,
        debounce: 250,
    });

    const loadMoreResults = async(extraVariables?: TVariables) => {
        const data = getData(result.value);
        const cursor = data?.pageInfo.endCursor;

        if (!cursor || loading.value) {
            return;
        }

        await fetchMore({
            variables: {
                ...v.value,
                after: cursor,
                ...(extraVariables || {}),
            },
            updateQuery(previousQueryResult, options) {
                if (!options.fetchMoreResult) {
                    return previousQueryResult;
                }

                const previousQueryData = getData(previousQueryResult)!;
                const fetchMoreData = getData(options.fetchMoreResult)!;

                let key: string | undefined;
                for (const d of query.definitions as ExecutableDefinitionNode[]) {
                    if (key) break;

                    for (const s of d.selectionSet.selections as FieldNode[]) {
                        if (!s.alias && s.kind === 'Field') {
                            key = s.name.value;
                            break;
                        }
                    }
                }

                if (!key) {
                    return previousQueryResult;
                }

                return {
                    ...previousQueryResult,
                    [key]: {
                        ...fetchMoreData,
                        edges: [
                            ...previousQueryData.edges,
                            ...fetchMoreData.edges,
                        ],
                    },
                };
            },
        });
    };

    const data = computed<TConnection | undefined>(() => getData(result.value));
    const hasMore = computed(() => data?.value?.pageInfo.hasNextPage || false);

    return {
        result,
        data,
        loading,
        refetch,
        hasMore,
        loadMoreResults,
    };
}
